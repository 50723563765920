<template>
  <div>
    <b-card>

      <b-row>

        <!-- User Info: Left col -->
        <b-col
          cols="21"
          xl="6"
          class="d-flex justify-content-between flex-column"
        >
          <!-- User Avatar & Action Buttons -->
          <div class="d-flex justify-content-start">
            <b-avatar
              :src="userData.image"
              :text="avatarText(userData.name)"
              :variant="`light-success`"
              size="104px"
              rounded
            />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="mb-0">
                  {{ userData.name }}
                </h4>
                <span class="card-text">{{ userData.email }}</span>
              </div>
              <div class="d-flex flex-wrap">
                <b-button
                  variant="outline-danger"
                  class="ml-1"
                >
                  Block
                </b-button>

                <b-button
                  v-if="$can('manage', 'all')"
                  v-b-modal.modal-center
                  variant="outline-primary"
                  class="ml-1"
                >
                  Update Data
                </b-button>
              </div>
            </div>
          </div>

          <!-- User Stats -->
          <div class="d-flex align-items-center mt-2">
            <div class="d-flex align-items-center mr-2">
              <b-avatar
                variant="light-primary"
                rounded
              >
                <feather-icon
                  icon="DollarSignIcon"
                  size="18"
                />
              </b-avatar>
              <div class="ml-1">
                <h5 class="mb-0">
                  23.3k
                </h5>
                <small>Monthly Sales</small>
              </div>
            </div>

            <div class="d-flex align-items-center">
              <b-avatar
                variant="light-success"
                rounded
              >
                <feather-icon
                  icon="TrendingUpIcon"
                  size="18"
                />
              </b-avatar>
              <div class="ml-1">
                <h5 class="mb-0">
                  $99.87k
                </h5>
                <small>Annual Profit</small>
              </div>
            </div>
          </div>
        </b-col>

        <!-- Right Col: Table -->
        <b-col
          cols="12"
          xl="6"
        >
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="UserIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Username</span>
              </th>
              <td class="pb-50">
                {{ userData.name }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="CheckIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Status</span>
              </th>
              <td class="pb-50 text-capitalize">
                <b-badge
                  pill
                  :variant="userData.approved ? 'light-success' : 'light-danger'"
                >
                  <feather-icon
                    :icon="userData.approved ? 'CheckIcon' : 'XIcon'"
                    class="mr-25"
                  />
                  <span>{{ userData.approved ? 'Active' : 'Inactive' }}</span>
                </b-badge>
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Role</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ userData.userType === 0 ? 'Broker' : userData.userType === 1 ? 'Sales Advisor' : userData.userType === 2 ? 'Manager' : 'Lead' }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="FlagIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Is SignedUp</span>
              </th>
              <td class="pb-50">
                <b-badge
                  pill
                  :variant="userData.isSignedUp ? 'light-success' : 'light-danger'"
                >
                  <feather-icon
                    :icon="userData.isSignedUp ? 'CheckIcon' : 'XIcon'"
                    class="mr-25"
                  />
                  <span>{{ userData.isSignedUp ? 'Yes' : 'No' }}</span>
                </b-badge>
              </td>
            </tr>
            <tr>
              <th>
                <feather-icon
                  icon="PhoneIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Contact</span>
              </th>
              <td>
                {{ userData.fullMobile }}
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-center"
      centered
      hide-backdrop
      no-close-on-backdrop
      title="Update User Data"
      ok-only
      ok-title="Update"
      @ok="approveUserFunction"
    >
      <b-card-text>
        <validation-observer ref="simpleRules">
          <b-form>
            <b-row>
              <b-col md="6">
                <b-form-group label="First Name *">
                  <b-form-input
                    v-model="userData.firstName"
                    placeholder="First Name"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="Last Name"
                  rules="required"
                >
                  <b-form-group label="Last Name *">
                    <b-form-input
                      v-model="userData.lastName"
                      placeholder="Last Name"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col md="12">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules=""
                >
                  <b-form-group label="Email">
                    <b-form-input
                      v-model="userData.email"
                      placeholder="Email"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>

              </b-col>
              <b-col md="12">
                <b-form-group label="Creation Date *">
                  <validation-provider
                    #default="{ errors }"
                    name="Creation Date"
                    rules="required"
                  >
                    <b-form-datepicker
                      v-model="userData.createdExcel"
                      :state="errors.length > 0 ? false:null"
                      class="mb-1"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BButton, BAvatar, BRow, BCol, BBadge, BFormInput, BFormGroup, BForm, BFormDatepicker, BCardText,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { avatarText } from '@core/utils/filter'
// eslint-disable-next-line import/extensions
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { mapActions } from 'vuex'

export default {
  name: 'UserViewUserInfoCard',
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BBadge,
    BFormInput,
    BFormGroup,
    BForm,
    BFormDatepicker,
    BCardText,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
    }
  },
  methods: {
    ...mapActions({
      updateUser: 'edit',
    }),
    async approveUserFunction() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          await this.updateUser({
            form: {
              name: this.userData.firstName,
              last_name: this.userData.lastName,
              email: this.userData.email,
              created_at: this.userData.createdExcel,
            },
            url: `admin/users/app/${this.$route.params.id}`,
            storeName: 'users',
          }).then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: 'Success',
                icon: 'CheckIcon',
                variant: 'success',
                text: 'Updated Successfully',
              },
            })
            this.$router.go(-1)
          }).catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: 'Error',
                icon: 'XIcon',
                variant: 'danger',
                text: error,
              },
            })
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: 'Error',
              icon: 'XIcon',
              variant: 'danger',
              text: 'Insert required data',
            },
          })
        }
      })
    },
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>

<style scoped>

</style>
