<template>
  <b-card title="User Activities">
    <app-timeline>

      <!-- 12 INVOICES HAVE BEEN PAID -->
      <app-timeline-item
        v-for="(item) in activities"
        :key="item.id"
        variant="info"
        :title="item.title"
        :subtitle="item.description"
        :time="item.briefDate"
      />
    </app-timeline>
  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  name: 'UserViewUserTimelineCard',
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    activities: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style scoped>

</style>
