<template>
  <b-card
    no-body
    class="border-primary"
  >
    <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
      <h5 class="mb-0">
        General Data
      </h5>
      <b-badge variant="light-primary">
        {{ userData.userType === 0 ? 'Broker' : userData.userType === 1 ? 'Sales Advisor' : userData.userType === 2 ? 'Manager' : 'Lead' }}
      </b-badge>
      <small class="text-muted w-100">Joined: {{ userData.created }}</small>
    </b-card-header>

    <b-card-body>
      <ul
        v-if="userData.userType === 0"
        class="list-unstyled my-1"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Leads Count</span>
            </th>
            <td class="pb-50">
              {{ userData.broker.leadsCount }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="HashIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">FRA Number</span>
            </th>
            <td class="pb-50">
              {{ userData.broker.fraNumber ? userData.broker.fraNumber : 'Not Set' }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CalendarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">FRA Expiry Date</span>
            </th>
            <td class="pb-50">
              {{ userData.broker.fraExpiryDate ? userData.broker.fraExpiryDate : 'Not Set' }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Expiry Approved</span>
            </th>
            <td class="pb-50">
              <b-badge
                pill
                :variant="userData.broker.fraExpiryDate ? 'light-success' : 'light-danger'"
              >
                <feather-icon
                  :icon="userData.broker.fraExpiryDate ? 'CheckIcon' : 'XIcon'"
                  class="mr-25"
                />
                <span>{{ userData.broker.fraExpiryDate ? 'Yes' : 'No' }}</span>
              </b-badge>
            </td>
          </tr>
        </table>
      </ul>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'UserViewGeneralDataCard',
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
}
</script>
