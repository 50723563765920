<template>
  <div>
    <div v-if="userData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="9"
          lg="8"
          md="7"
        >
          <user-view-user-info-card :user-data="userData" />
        </b-col>
        <b-col
          cols="12"
          md="5"
          xl="3"
          lg="4"
        >
          <user-view-general-data-card :user-data="userData" />
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="12"
          lg="6"
        >
          <user-view-user-timeline-card :activities="userData.activities" />
        </b-col>
        <!--        <b-col
          cols="12"
          lg="6"
        >
          <user-view-user-permissions-card />
        </b-col>-->
      </b-row>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import UserViewUserInfoCard from '@/views/pages/users/users_view/UserViewUserInfoCard'
// eslint-disable-next-line import/extensions
import UserViewUserTimelineCard from '@/views/pages/users/users_view/UserViewUserTimelineCard'
import {
  BRow, BCol,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
// eslint-disable-next-line import/extensions
import UserViewGeneralDataCard from '@/views/pages/users/users_view/UserViewGeneralDataCard'

export default {
  name: 'ViewUser',
  components: {
    UserViewGeneralDataCard, UserViewUserTimelineCard, UserViewUserInfoCard, BRow, BCol,
  },
  data() {
    return {
      updatedUserData: {

      },
    }
  },
  computed: {
    ...mapGetters({
      userData: 'users/userData',
    }),
  },
  created() {
    this.loadUserDataFunction()
  },
  methods: {
    ...mapActions({
      loadUserData: 'get',
    }),
    async loadUserDataFunction() {
      await this.loadUserData({
        storeName: 'users',
        url: `/admin/users/app/${this.$route.params.id}`,
        commitUrl: 'users/SET_USER',
      })
    },
  },
}
</script>

<style scoped>

</style>
